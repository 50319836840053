import {ref} from "vue";
import {defineStore} from "pinia";

export const useThread = defineStore('thread', {
    state: () => {
        return {
            thread: ref(null),
            threads: ref(null),
            orders: ref(null),
            messages: ref(null),
            hasNewMessage: ref(false),
            dialogElement: ref(null),
            messageChannel: ref(null),
            inputs: ref({}),
            users: ref([]),
        }
    },
})

