import {useThread} from "@/Store/Thread.js";
import {storeToRefs} from "pinia";
import {nextTick} from "vue";
import {route} from "ziggy-js";
import {router, usePage} from "@inertiajs/vue3";
import debounce from "lodash-es/debounce.js";
import {loadEcho} from "@/helper.js";
import {useUser} from "@/Store/User.js";

export default {
    install: (app, options) => {
        const threadStore = useThread()
        const {
            messageChannel,
            thread,
            messages,
            threads,
            dialogElement,
            hasNewMessage,
            orders
        } = storeToRefs(threadStore)

        const reload = () => {
            router.reload({
                only: ['threads', 'orders'],
                onSuccess: () => {
                    threads.value = usePage().props.threads.data
                    orders.value = usePage().props.orders.data
                }
            })
        }
        const fetchDebounced = debounce(reload, 10000)
        const updateMessageChannel = user => {
            if (typeof window === 'undefined') return;
            if (user) {
                if (messageChannel.value == null) {
                    messageChannel.value = window.Echo.private('App.Models.User.' + user.id.toString())
                    // console.log('subscribe ' + 'App.Models.User.' + user.id.toString())
                    messageChannel.value.listen('.MessageCreated', (e) => {
                        // console.log('message created', e)
                        if (e.model.user_id !== user.id && e.model.thread_id !== thread.value?.id) {
                            hasNewMessage.value = true
                        }
                        if (e.model.thread_id === thread.value?.id) {
                            messages.value[e.model.id] = e.model
                            if (e.model.offer) {
                                thread.value.user.offer = e.model.offer
                            }
                            nextTick().then(() => {
                                dialogElement.value.scrollBy(0, dialogElement.value.scrollHeight)
                            })
                        }
                        if (route().current('threads.*')) {
                            reload()
                        }
                        if (route().current('admin.threads.*')) {
                            fetchDebounced()
                        }
                    })
                }
            } else {
                if (messageChannel.value) {
                    messageChannel.value.stopListening('.MessageCreated')
                    messageChannel.value = null
                }
            }
        };
        const joinChannel = () => {
            if (typeof window === 'undefined') return;
            const userStore = useUser(),
                {usersOnline} = storeToRefs(userStore)
            return window.Echo.join('user_online')
                .here((users) => {
                    usersOnline.value = users.reduce((acc, cur) => {
                        acc[cur.id] = cur;
                        return acc;
                    }, {});
                })
                .joining((user) => {
                    // user: {id: 123}
                    usersOnline.value[user.id] = user
                })
                .leaving((user) => {
                    // user: {id: 123}
                    delete usersOnline.value[user.id]
                })
        }
        const updateChannel = user => {
            if (typeof window === 'undefined') return;
            const userStore = useUser(),
                {onlineChannel} = storeToRefs(userStore)
            if (user) {
                if (onlineChannel.value == null) {
                    onlineChannel.value = joinChannel()
                }
            } else {
                if (onlineChannel.value != null) {
                    window.Echo.leave('user_online')
                    onlineChannel.value = null
                }
            }
        };
        app.provide('updateChannel', updateChannel);
        app.provide('updateMessageChannel', updateMessageChannel);
        if (typeof window === 'undefined') return;
        loadEcho(() => {
            updateMessageChannel(options?.user)
            updateChannel(options?.user)
        })
    }
}
