import {getActiveLanguage, trans, wTrans} from "laravel-vue-i18n";

export const mainCategories = ['games', 'subscriptions'];
export const getChooseAll = () => ({id: 0, title: trans('All categories'), slug: 'products'})

export const getArrSortsReview = () => ([
        {title: wTrans('All feedbacks'), val: undefined},
        {title: undefined, val: 5},
        {title: undefined, val: 4},
        {title: undefined, val: 3},
        {title: undefined, val: 2},
        {title: undefined, val: 1}
    ]
)

export const getLangs = () => {
    return [
        {code: 'ru', name: 'Русский', short: trans('RUS')},
        {code: 'en', name: 'English', short: 'ENG'}
    ];
}

export const getLang = () => {
    const activeLang = getActiveLanguage()
    return getLangs().find(item => item.code === activeLang)
}

export const getCurrencies = () => [{code: 'RUB', name: 'Руб.', symbol: '₽'}, {code: 'USD', name: 'USD', symbol: '$'}]
export const HelpDeskUrl = 'https://anykod.freshdesk.com/support/home'
export const tgSupportUrl = 'https://t.me/anykod_support_bot'
export const tgUrl = 'https://t.me/anykod'
export const HelpDeskNewTicket = 'https://anykod.freshdesk.com/support/tickets/new'
export const myWotUrl = 'https://www.mywot.com/scorecard/anykod.com'



